<template>
  <sign-page
    title-text="岗位晋升预警"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :tableActionsFixed="true"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="tableActions"
    :need-data-file="true">
    <fm-modal v-model="modal" width="80%" :title="(chooseData ? (chooseData.type + chooseData.title) : '') + '上岗资格明细'">
      <div style="min-height:20rem" v-if="modal">
        <div class="modal-s">
          <fm-input v-model="searchname" @elBlur="search" placeholder="输入姓名搜索"></fm-input>
        </div>
        <div class="worker-card-list">
          <a href="javascript:;" v-for="(item, index) in showData" :key="index">
          <worker-card :data="item.worker" class="worker-card-item" @clickWorker="chooseWorker(item)">
            <promotion-condition :data="item"></promotion-condition>
          </worker-card>
          </a>
        </div>
      </div>
    </fm-modal>
    <fm-modal v-model="modalWorker" width="80%" title="个人上岗资格明细">
      <div style="min-height:20rem" v-if="modalWorker">
        <div class="worker-card-list">
          <WorkerPromotion :current="current" :worker="worker" :dataList="workerPromotionList"></WorkerPromotion>
        </div>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '@/api'

import WorkerCard from './worker/workerCard'

import PromotionCondition from './worker/promotionCondition'
import WorkerPromotion from './worker/workerPromotion'

export default {
  components: {
    WorkerCard,
    PromotionCondition,
    WorkerPromotion
  },
  methods: {
    chooseWorker (data) {
      this.worker = data.worker
      this.current = data.workerJobTitle
      this.workerPromotionList = this.allData.filter(v => v.worker.id === this.worker.id)
      this.modalWorker = true
    },
    search () {
      this.name = this.searchname
    },
    async loadData () {
      let datas = await request.getQueueWarning()
      datas.sort((a, b) => {
        return (a.lelel || 0) - (b.lelel || 0)
      })
      let allData = []
      datas.forEach(v => {
        allData = allData.concat(v.queue)
        v.queue = v.queue.filter(v1 => v1.isMatch)
        v.matchLength = v.queue.length
        v.note = v.queue.length > 0 ? '有' + v.queue.length + '人现在或即将具有上岗资格，请尽快安排岗位调整' : '请尽快安排岗位调整'
      })
      this.allData = allData
      return datas
    },
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
      this.searchname = ''
      this.name = ''
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return [{
        'key': 'detail',
        label: '明细',
        show: (data) => {
          return data.queue.length > 0
        }
      }]
    },
    showData () {
      let data = this.chooseData ? this.chooseData.queue : []
      return data.filter(v => !this.name || v.worker.name.includes(this.name))
    },
    columnList: {
      get () {
        let data = [{
          title: '岗位类型',
          sort: true,
          field: 'type',
          filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel',
          filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'title',
          filterMethod (parm) {
            return parm.row.title === parm.value
          }
        },
        // {
        //   title: '需要资格证书',
        //   field: 'needCertificateTitle'
        // },
        // {
        //   title: '需要职称',
        //   field: 'needJobTitleTitle'
        // },
        // {
        //   title: '需要职称年限',
        //   field: 'needYear'
        // },
        {
          title: '实际可用岗位数',
          sort: true,
          field: 'num',
          filterRange: true,
          dataType: Number
        },
        {
          title: '岗位实际人数',
          sort: true,
          field: 'workerJobTitleNum',
          filterRange: true,
          dataType: Number
        },
        {
          title: '岗位空置数',
          sort: true,
          filterRange: true,
          field: 'lastNum',
          dataType: Number,
          render: (h, rowData) => {
            return h('div', isNaN(rowData.lastNum) ? '-' : (rowData.lastNum < 0 ? 0 : rowData.lastNum))
          }
        },
        {
          title: '具有上岗资格人数',
          filterRange: true,
          sort: true,
          field: 'matchLength',
          dataType: Number
        },
        {
          title: '提醒',
          width: 200,
          field: 'note'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      searchname: '',
      allData: [],
      name: '',
      modal: false,
      chooseData: null,
      worker: null,
      workerPromotionList: [],
      modalWorker: false,
      current: null
    }
  }
}
</script>

<style lang="less" scoped>
.worker-card-list {
  display: flex;
  width: 100%;
  overflow-x:auto;
  .worker-card-item {
    margin-right: 1rem;
  }
}
.modal-s {
  position: absolute;
  top: 6px;
  right: 60px;
  display: flex;
  justify-content: flex-end;
}
</style>
